/** @jsx jsx */

import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx, Themed } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetHeading" }>;
}

const HeadingWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const type = widget.headingShape?.type as
    | ("h1" | "h2" | "h3" | "h4" | "h5" | "h6")
    | undefined;

  const Tag = type ? Themed[type] : Themed.h1;

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Tag
        sx={{
          textAlign: widget.headingShape?.alignment || "left",
        }}
      >
        {widget.headingShape?.text}
      </Tag>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetHeading on WidgetHeading {
    id
    __typename
    headingShape {
      type
      text
      alignment
    }
  }
`;

export default memo(HeadingWidget);
